import React from "react";
import STAR_ORANGE from "@assets/icons/Lims/star-orange.svg";
import { StaticImage } from "gatsby-plugin-image";
import RECTANGLE_2 from "@assets/icons/Lims/rectangle-blue-2.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-blue-1.svg";
import STAR_BLUE_1 from "@assets/icons/Lims/star-blue-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./ComprehensivePM.module.scss";
import { WhyLimsProps } from "@components/LimsSoftware/SectionWhyLims/data";
import { SectionWhyLIMS } from "@components/LimsSoftware/SectionWhyLims";
import { useWindowSizeType } from "@helpers/Hooks";

export const ComprehensivePM = () => {
	const windowSizeType = useWindowSizeType();

	const props: WhyLimsProps = {
		title: "Comprehensive project management",
		starOnTitle: <SVG src={STAR_BLUE_1} className={styles.starBlue1} />,
		description:
			"An Electronic lab notebook (ELN) enables directors and lab managers to coordinate projects by generating reports, tracking activities, and controlling access permissions.",
		list: [
			{
				text: "Export experiments to generate PDF reports",
				icon: STAR_ORANGE,
				id: 1,
			},
			{
				text: "Keep track of changes made to the document",
				icon: STAR_ORANGE,
				id: 2,
			},

			{
				text: "Manage access permissions for each project",
				icon: STAR_ORANGE,
				id: 3,
			},
		],
		link: "NOTEBOOK",
		bodyTextColor: "text-helper",
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_2} className={styles.rectangle2} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/eln/protocol-draft-editor.png"}
					alt="Protocol Preview"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
		reverse:
			windowSizeType === "mobile" 
				? false
				: true,
		gap: "gapLg",
		sectionOverviewOverride: styles.sectionOverviewOverride,
	};
	return <SectionWhyLIMS {...props} />;
};
